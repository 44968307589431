import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import kebabCase from "lodash/kebabCase"

const Header = ({ siteTitle }) => {
  const [isOpen, setIsOpen] = useState(false)
  const data = useStaticQuery(graphql`
    query {
      categoryGroup: allAirtable(
        limit: 2000
        filter: {
          table: { eq: "Products" }
          data: { Diameter_mm_calc: { gt: 0 }, Published: { eq: true } }
        }
      ) {
        group(field: data___Category) {
          categoryName: fieldValue
          totalCount
        }
      }
    }
  `)
  return (
    <header className="bg-indigo-600">
      <div className="flex items-center justify-between px-4 py-3">
        <div>
          <h1>
            <Link
              className="text-indigo-300 no-underline focus:text-white focus:outline-none hover:text-white"
              to="/"
            >
              {siteTitle}
            </Link>
          </h1>
        </div>
        <div>
          <button
            type="button"
            className="block text-indigo-300 focus:text-white focus:outline-none hover:text-white"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              className="h-6 w-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              {isOpen ? (
                <path
                  fill-rule="evenodd"
                  d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                />
              ) : (
                <path
                  fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
        </div>
      </div>
      <div className={`px-2 pt-2 pb-4 ${isOpen ? "block" : "hidden"}`}>
        {data.categoryGroup.group.map(category => (
          <Link
            className="block px-2 py-1 mt-1 text-indigo-100 font-semibold hover:bg-indigo-900 rounded"
            key={category.categoryName}
            to={`/browse/${kebabCase(category.categoryName)}`}
          >
            {category.categoryName} | {category.totalCount}
          </Link>
        ))}
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
